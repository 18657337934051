<template>
  <div class="coustomer_join">
    <div class="coustomer_join_filter">
      <div
        :class="[currentStatus == -1 ? 'active' : '']"
        @click="changeStatus(-1)"
      >
        全部 ({{ $store.state.customer_count.total }})
      </div>
      <div
        :class="[currentStatus == 0 ? 'active' : '', 'needVisit']"
        @click="changeStatus(0)"
      >
        <span v-if="this.$store.state.count"></span>
        未回访 ({{ $store.state.customer_count.no_visit }})
      </div>
      <div
        :class="[currentStatus == 1 ? 'active' : '']"
        @click="changeStatus(1)"
      >
        已回访 ({{ $store.state.customer_count.is_visit }})
      </div>
    </div>
    <div class="delete_search">
      <button
        :disabled="
          currentStatus == 0 || currentStatus == -1 || currentStatus == 1
        "
      >
        删除
      </button>
      <div
        :class="[{ search_wapper: true }, { showFloat: currentStatus == 0 }]"
      >
        <div class="select_filter">
          <el-select
            v-model="filterValue"
            class="m-2"
            placeholder="Select"
            size="small"
            @change="chengeInputFilter"
          >
            <el-option
              v-for="item in selectFilterOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="search_filter">
          <input
            type="text"
            v-model="searchValue"
            :placeholder="placeholderText"
            @change="search"
          />
          <img class="icon_search" src="../../../assets/search.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 回访弹出框 -->
    <div class="mian_table">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="showRevisit"
        title="回访"
        width="22%"
      >
        <div class="line_message"></div>
        <div class="form_messagebox">
          <el-form
            ref="revisitForm"
            :rules="rules"
            :model="revisitForm"
            label-width="120px"
          >
            <el-form-item prop="name" label="回访者">
              <el-input v-model="revisitForm.name" />
            </el-form-item>
            <el-form-item prop="time" label="回访时间">
              <el-date-picker
                v-model="revisitForm.time"
                type="datetime"
                placeholder="请选择时间"
              />
            </el-form-item>
            <el-form-item prop="info" label="备注">
              <el-input
                v-model="revisitForm.info"
                type="textarea"
                placeholder="请输入"
              />
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="calloffRevisit('revisitForm')">取消</el-button>
            <el-button @click="confirmRevisit('revisitForm')" type="primary"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 回访详情 -->
    <div class="mian_table">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="showLookinfo"
        title="回访详情"
        width="22%"
      >
        <div class="line_message"></div>
        <div class="form_messagebox">
          <el-form :model="lookInfoForm" label-width="120px">
            <el-form-item class="" label="回访者">
              <span>{{ lookInfoForm.name }}</span>
            </el-form-item>
            <el-form-item label="回访时间">
              <span>{{ lookInfoForm.revisit_time }}</span>
            </el-form-item>
            <el-form-item label="备注">
              <span>{{ lookInfoForm.revisit_info }} </span>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="showLookinfo = false">取消</el-button>
            <el-button type="primary" @click="showLookinfo = false"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 表格1 -->
    <div v-show="table1" class="main_table">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%"
        border
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          v-for="(item, index) in currentColums"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.type == 'text'">
              {{ scope.row[item.prop] ? scope.row[item.prop] : '-' }}
            </span>
            <span v-else-if="item.type == 'temp' && item.prop == 'is_revisit'">
              <span
                :class="['circle', scope.row.status == 1 ? 'activeCircle' : '']"
              ></span
              ><span
                :class="['upcir', scope.row.status == 1 ? 'activeUpcir' : '']"
                >{{ scope.row.status == 1 ? '已回访' : '未回访' }}</span
              >
            </span>
            <span
              @click="operationFun(scope.row)"
              class="info_revisit_btn"
              v-else-if="item.type == 'temp' && item.prop == 'operation'"
            >
              {{ scope.row.status == 1 ? '详情' : '回访' }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="infofoter">
      <div class="totle">共{{ total }}条数据</div>
      <el-pagination
        background
        :page-sizes="[10, 20, 30, 40]"
        layout="sizes,prev, pager, next,jumper"
        :total="total"
        class="page_div"
        :current-page="payloadList.page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      revisitForm: {},
      lookInfoForm: {},
      showRevisit: false,
      showLookinfo: false,
      allColumnsed: [
        { prop: 'name', label: '客户名称', type: 'text', width: '150' },
        { prop: 'phone', label: '联系方式', type: 'text', width: '150' },
        { prop: 'fact_name', label: '工厂名称', type: 'text', width: '150' },
        { prop: 'fact_location', label: '工厂地址', type: 'text' },
        { prop: 'create_time', label: '提交时间', type: 'text' },
        { prop: 'operation', label: '操作', type: 'temp' },
      ],
      revisitColumnsed: [
        { prop: 'name', label: '客户名称', type: 'text', width: '150' },
        { prop: 'phone', label: '联系方式', type: 'text', width: '150' },
        { prop: 'fact_name', label: '工厂名称', type: 'text', width: '150' },
        { prop: 'revisit_name', label: '回访者', type: 'text' },
        { prop: 'create_time', label: '提交时间', type: 'text' },
        { prop: 'revisit_time', label: '回访时间', type: 'text' },
        { prop: 'is_revisit', label: '回访状态', type: 'temp' },
        { prop: 'operation', label: '操作', type: 'temp' },
      ],
      payloadList: {
        name: '',
        phone: '',
        fact_name: '',
        revisit_name: '',
        status: -1,
        page: 1,
        limit: 9,
      },
      table1: true,
      table2: false,
      showCircle: true, //显示小圆点
      val: 1,
      limit: 10, //每页显示的条数
      multipleSelection: [],
      total: 0,
      getStatus: {}, //拿到点击回访的id
      dialogVisible: false, //弹出框
      dialogVisible2: false, //弹出框
      currentStatus: -1, //筛选状态
      filterValue: 'name', //phone,fact_name,revisit_name
      searchValue: '', //搜索值
      // 回访数
      allCount: 0, //全部
      visitCount: 0, //未回访
      visitedCount: 0, //已回访
      selectFilterOption: [
        {
          value: 'name',
          label: '客户姓名',
        },
        {
          value: 'phone',
          label: '联系方式',
        },
        {
          value: 'fact_name',
          label: '工厂名称',
        },
        {
          value: 'revisit_name',
          label: '回访者',
        },
      ],
      // 表格
      tableData: [],
      //正则判断
      rules: {
        name: [{ required: true, message: '请输入回访者', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        info: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
    }
  },
  watch: {},
  computed: {
    currentColums() {
      let current = []
      if (this.currentStatus == -1 || this.currentStatus == 1) {
        current = this.revisitColumnsed
      } else {
        current = this.allColumnsed
      }
      return current
    },
    placeholderText() {
      return (
        '请输入你需要搜索的' +
        this.selectFilterOption.find((item) => item.value === this.filterValue)
          .label
      )
    },
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      this.$request.getRevisitList(this.payloadList, (res) => {
        console.log('客户加盟列表res', res)
        this.tableData = res.data.res
        this.total = res.data.total
      })
    },
    // 改变搜索
    chengeInputFilter() {
      this.payloadList.name = ''
      this.payloadList.phone = ''
      this.payloadList.fact_name = ''
      this.payloadList.revisit_name = ''
    },
    // 搜索
    search() {
      console.log('this.filterValue', this.filterValue)
      switch (this.filterValue) {
        case 'name':
          this.payloadList.name = this.searchValue
          break
        case 'phone':
          this.payloadList.phone = this.searchValue
          break
        case 'fact_name':
          this.payloadList.fact_name = this.searchValue
          break
        case 'revisit_name':
          this.payloadList.revisit_name = this.searchValue
          break
      }
      this.getList()
    },
    // 操作 （查看/回访）
    operationFun(row) {
      console.log(row)
      if (row.status == 1) {
        console.log('查看')
        this.showLookinfo = true
        this.lookInfoForm = {
          name: row.name,
          revisit_time: row.revisit_time,
          revisit_info: row.revisit_info,
        }
      } else {
        console.log('回访')
        this.revisitForm.id = row.id
        this.showRevisit = true
      }
    },
    // 确定回访
    confirmRevisit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.revisitForm.time = this.bjTime(this.revisitForm.time)
          this.$request.updataRevisit(this.revisitForm, (res) => {
            this.$message.success('回访成功')
            this.showRevisit = false
            this.$store.dispatch('getCustomerCount')
            this.getList()
          })
        } else {
          this.$message.warning('请填写信息')
          return false
        }
      })
    },
    // 取消回访
    calloffRevisit(formName) {
      this.showRevisit = false
      this.$refs[formName].resetFields()
    },
    // 改变回访 全部 未回访
    changeStatus(num) {
      console.log('num', num)
      this.currentStatus = num
      this.payloadList.page = 1
      this.payloadList.status = num
      this.getList()
    },
    // 每页大小
    handleSizeChange(limit) {
      this.payloadList.limit = limit
      this.getList()
    },
    // 翻页
    handleCurrentChange(page) {
      this.payloadList.page = page
      this.getList()
    },
    // 格式化时间
    bjTime(timestamp) {
      const d = new Date(timestamp)
      const month =
        d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
      const date = d.getFullYear() + '-' + month + '-' + d.getDate()
      const hour = d.getHours() > 9 ? d.getHours() : '0' + d.getHours()
      const minute = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()
      const ss = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
      const time = date + ' ' + hour + ':' + minute + ':' + ss
      return time
    },
  },
}
</script>

<style scoped lang="less">
.coustomer_join {
  text-align: left;
  background-color: #fff;
  height: 100%;
  padding: 0 16px;
  .coustomer_join_filter {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
    > div {
      padding: 21px 16px;
      cursor: pointer;
      position: relative;
      bottom: -1px;
      color: #00000099;
      font-size: 14px;
      &.active {
        border-bottom: 2px solid rgba(100, 118, 255, 1);
        color: #6476ffff;
      }
      &.needVisit {
        > span {
          position: absolute;
          top: 18px;
          right: 7px;
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: rgba(227, 77, 89, 1);
        }
      }
    }
  }
  .delete_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    // position: relative;
    // .showFloat {
    //   position: absolute;
    //   right: 0px;
    //   top: 24px;
    // }
    button:nth-child(1) {
      cursor: pointer;
      background: #ffffff;
      color: #000000 90%;
      font-size: 14px;
      width: 60px;
      height: 32px;
      width: 60px;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #dcdcdc;
    }
    .search_wapper {
      width: 400px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #dcdcdc;
      display: flex;
      align-items: center;
      padding: 8px 0;
      .select_filter {
        width: 90px;
        border-right: 1px solid rgba(0, 0, 0, 0.26);
        ::v-deep .el-input__inner {
          border: none;
          box-shadow: none;
        }
        /deep/ .el-select {
          width: 100px;
          .el-input__wrapper {
            &.is-focus {
              box-shadow: none !important;
            }
          }
          .el-input {
            &.is-focus {
              .el-input__wrapper {
                &.is-focus {
                  box-shadow: none !important;
                }
                box-shadow: none !important;
              }
            }
            .el-input__wrapper {
              box-shadow: none;
            }
          }
        }
      }
      .search_filter {
        flex: 1;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          color: #00000066;
          font-size: 14px;
          width: 80%;
          outline: none;
          border: none;
        }
        .icon_search {
          cursor: pointer;
          width: 18px;
          height: 19px;
        }
      }
    }
  }
  .mian_table {
    margin-top: 24px;
    .form_messagebox {
      text-align: left;
      margin-left: -47px;
      color: #00000099;
    }
  }
}
.main_table {
  span {
    margin-right: 5px;

    cursor: pointer;
  }
  .activeCircle {
    background: #00a870 !important;
  }
  .circle {
    background: rgb(204, 204, 204);
    border-radius: 50%;
    opacity: 1;
    width: 8px;
    height: 8px;
    display: inline-block;
  }
  .activeUpcir {
    color: #00a870 !important;
  }
  .upcir {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgb(204, 204, 204);
  }
  .info_revisit_btn {
    cursor: pointer;
    color: #155bd4;
  }
}
.infofoter {
  background: #f3f3f3ff;
  height: 64px;
  // background: red;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  .totle {
    height: 100%;
    line-height: 64px;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
